import React from 'react'
import HomeMenu from './HomeMenu'
// import ProjectManagement from './ProjectManagement'
// import IssuesHistory from './IssuesHistory'
import LiveSearch from './LiveSearch'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'

class TopBar extends React.Component {
  // <div className="mr-4">
  //   <IssuesHistory />
  // </div>
  // <div className="mr-4">
  //   <ProjectManagement />
  // </div>
  render() {
    return (
      <div className={styles.topbar}>

        <div className="mr-auto">
          <LiveSearch />
        </div>
        <div className="mr-4">
          <HomeMenu />
        </div>
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
