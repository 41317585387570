import React from 'react'
import styles from './style.module.scss'
import logo from '../../../assets/logo_dp_cortado.png'

const Footer = () => (
  <div className={styles.footer}>
    <div className={styles.inner}>
      <div className={styles.bottom}>
        <div className={styles.copyright}>
          <div className={styles.containerFooter}>
            <img src={logo} rel="noopener noreferrer" alt="FMS" />
            <span>
              © 2019{' '}
              <a href="#" rel="noopener noreferrer">
                DP World Posorja | FMS
              </a>
              <br />
              Todos los derechos reservados
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
