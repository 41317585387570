import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import moment from 'moment'
import 'moment/locale/es'

import sagas from 'redux/sagas'
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { store, history, sagaMiddleware } from './redux/store'
import restClient from './services/restClient'
import authAction from './redux/user/actions'
import FirebaseStartComponent from './firebase'
import * as serviceWorker from './serviceWorker'

// app styles
import './global.scss'

moment.locale('es')

const { logout } = authAction

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
    }

    this.persistor = persistStore(store, undefined, () => {
      const profile = store.getState().user.profile
      restClient.setTokenToAxio(profile ? profile.token : null)
      restClient.setInterceptor(function() {
        store.dispatch(logout())
      })

      sagaMiddleware.run(sagas)
      this.setState({ loaded: true })
    })
  }
  render() {
    return (
      <div>
        <Provider store={store}>
          {this.state.loaded ? (
            <PersistGate loading={null} persistor={this.persistor}>
              <Localization>
                <Router history={history} />
              </Localization>
              <FirebaseStartComponent />
            </PersistGate>
          ) : (
            <p>Loading</p>
          )}
        </Provider>
      </div>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.register()

export { store, history }
