import axios from 'axios'
const urlAPIAPP = 'http://localhost:5002/reservas/iniciar_hito_manual'

export default class RestClientObj {
  static instanceAxios = axios.create({
    // baseURL: 'http://localhost:5001',
    // baseURL: 'https://api.fmsdp.com',
    baseURL: 'https://apidev.fmsdp.com',
    //  baseURL: 'http://157.100.65.66:5001',
  })

  static setInterceptor = callback => {
    RestClientObj.instanceAxios.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        if (!error.response) {
          callback()
        }
        if (error.response.status === 403) {
          callback()
        } else {
          // if(!error.response.data.message.mensaje)
          //   throw Error(error.response.data.message.mensaje)
          // else
          console.log(error.response.data.message)
          throw Error(error.response.data.message)
        }
      },
    )
  }

  static setTokenToAxio = token => {
    RestClientObj.instanceAxios.defaults.headers.common.Authorization = token
  }
  static cleartokenAxio = () => {
    delete RestClientObj.instanceAxios.defaults.headers.common.Authorization
    // RestClientObj.instanceAxios.defaults.headers.common.Authorization = undefined
  }

  static setAuthorization = () => {
    // if (!RestClientObj.instanceAxios.defaults.headers.common.Authorization)
    //   RestClientObj.instanceAxios.defaults.headers.common.Authorization = localStorage.getItem(
    //     'token',
    //   )
  }

  static perfilMenu = userData => {
    return RestClientObj.instanceAxios.post('/users/getPerfilMenu', userData)
  }

  static userLogin = userData => {
    this.cleartokenAxio()
    return RestClientObj.instanceAxios.post('/users/login', userData)
  }

  static getPerfilUser = userData => {
    return RestClientObj.instanceAxios.post('/users/getPerfil', userData)
  }

  static getProvincia = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getProvincia', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getCiudad', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static iniciarHito = filter => {
    const tmp = { ...RestClientObj.instanceAxios }
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: tmp.defaults.headers.common.Authorization,
      },
    }
    return axios
      .post(urlAPIAPP, filter, axiosConfig)
      .then(response => response.data)
      .catch(error => error.response)
  }
  static getCiudadNombre = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getCiudadNombre', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getTipoMovimiento = filter => {
    return RestClientObj.instanceAxios
      .get('/admin/ruta_waypoint/getTipoMovimiento', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarDatosTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarDatosTransportista', filter)
      .then(response => response.data)
  }

  static consultaCamionesAsociados = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/consultaCamionesAsociados', filter)
      .then(response => response.data)
  }

  static actualizarEstadoAsociaciones = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarEstadoAsociaciones', filter)
      .then(response => response.data)
  }

  static consultaMisAsocianesTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/consultaMisAsocianesTransportista', filter)
      .then(response => response.data)
  }

  static consultaAsociadoTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/consultaAsociadoTransportista', filter)
      .then(response => response.data)
  }

  static quitarAsignacionTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/quitarAsignacionTransportista', filter)
      .then(response => response.data)
  }

  static getTransportistasList = filter => {
    return RestClientObj.instanceAxios.post('/admin/transportista/', filter)
  }

  static getTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static addTransportistaPreferido = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/addTransportistaPreferido/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaTransportistaPreferido = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getListaTransportistaFavoritos/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarEstadoTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/actualizarEstadoTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEstablecimientos = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }
  /**
   * Obtiene un Establecimiento por ID o Tag
   * @param {id_establecimiento: number, tag: string - "DP" } filter
   */
  static getEstablecimiento = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimiento/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  /**
   * Obtiene una Ruta por IDs de Establecimientos de Origen y Destino
   * @param id_establecimiento_origen: number
   * @param id_establecimiento_destino: number
   */
  static getRutaByEstablecimientos = (id_est_orig, id_est_dest) => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getRutaByEstablecimientos/', {
        id_establecimiento_origen: id_est_orig,
        id_establecimiento_destino: id_est_dest,
      })
      .then(response => response.data)
  }

  static getEstablecimientosMonitoring = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientosMonitoring/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaNavieras = () => {
    return RestClientObj.instanceAxios.get('/vacio/').then(response => response.data)
  }

  static getListaVacios = filter => {
    return RestClientObj.instanceAxios.post('/vacio/list/', filter).then(response => response.data)
  }

  static agregarVacio = filter => {
    return RestClientObj.instanceAxios
      .post('/vacio/addVacio', filter)
      .then(response => response.data)
  }

  static agregarHito = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/addHito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarHito = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminarHito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  /**
   * Guarda una reserva de Import
   * @param documento: Utils.Documento
   * @param reserva: Utils.Reserva
   * @param hitos Utils.Reserva.getHitos() - Arreglo de hitos
   */
  static guardarReservaImport = (documento, reserva, hitos) => {
    return RestClientObj.instanceAxios
      .post('/reservas/crear_reserva_import/', {
        documento: documento,
        reserva: reserva,
        hitos: hitos,
      })
      .then(response => response.data)
  }

  static testHorarioDisponible = (documento, reserva) => {
    return RestClientObj.instanceAxios
      .post('/reservas/dp_consultar_disponibilidad_hack/', {
        documento: documento,
        reserva: reserva,
      })
      .then(response => response.data)
  }

  /**
   * Guarda una reserva de Export
   * @param documento: Utils.Documento
   * @param reserva: Utils.Reserva
   * @param hitos Utils.Reserva.getHitos() - Arreglo de hitos
   */
  static guardarReservaExport = (documento, reserva, hitos) => {
    return RestClientObj.instanceAxios
      .post('/reservas/crear_reserva_export/', {
        documento: documento,
        reserva: reserva,
        hitos: hitos,
      })
      .then(response => response.data)
  }

  /**
   * Guarda una reserva de Import
   * @param {number} id_transportista ID del transportista seleccionado
   * @param {numbre} id_reserva  ID de reserva
   * @param {string} fecha_reserva_inicio  -- format "YYYY-MM-DD HH:mm:ss"
   * @param {string} fecha_reserva_fin  -- format "YYYY-MM-DD HH:mm:ss"
   */
  static seleccionTransportistaImport = (
    id_transportista,
    id_reserva,
    fecha_reserva_inicio,
    fecha_reserva_fin,
  ) => {
    return RestClientObj.instanceAxios
      .post('/reservas/seleccion_transportista/', {
        id_transportista: id_transportista,
        id_reserva: id_reserva,
        fecha_reserva_inicio: fecha_reserva_inicio,
        fecha_reserva_fin: fecha_reserva_fin,
      })
      .then(response => response.data)
  }

  /**
   * Guarda una reserva de Import
   * @param {string} desde - format - "YYYY-MM-DD HH:mm:ss"
   * @param {string} hasta - format - "YYYY-MM-DD HH:mm:ss"
   * @param {number} tiempo_prudencial - (minutos) tiempo que se considera antes y después para considerar un camión disponible
   */
  static getTransportistasDisponiblesImport = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/disponibilidad_transportistas_import/', filter)
      .then(response => response.data)
  }

  /**
   * Cancelar reserva en DP
   * @param reserva:
   */
  static cancelarReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/cancelar_cita_dp/', filter)
      .then(response => response.data)
  }

  static listaReservas = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/listaReservas/', filter)
      .then(response => response.data)
      .catch(error => error)
  }

  static getEstados = filter => {
    return RestClientObj.instanceAxios
      .get('/reservas/estados/')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaCiudades = filter => {
    return RestClientObj.instanceAxios
      .get('/ciudad/lista/')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static ordenarHitos = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/ordenarHitos/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static deleteEstablecimiento = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/deleteEstablecimiento', filter)
  }

  static getMarkerEstablecimientos = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getMarkerEstablecimiento/', filter)
      .then(response => response.data)
  }

  static getPoligono = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getPoligono/', filter)
      .then(response => response.data)
  }

  static addEstablecimiento = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/addEstablecimiento', filter)
      .then(response => response.data)
  }

  static editEstablecimiento = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/editEstablecimiento', filter)
      .then(response => response.data)
  }

  static getTipoEstablecimiento = () => {
    return RestClientObj.instanceAxios
      .get('/establecimiento/getTipoEstablecimiento/')
      .then(response => response.data)
  }

  static getMapBounds = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/get_bounds/', filter)
      .then(response => response.data)
  }

  static getEstablecimientoByCiudad = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getListaEstablecimiento/', filter)
  }

  static getCoordenadaByEstablecimiento = filter => {
    return RestClientObj.instanceAxios.post(
      '/establecimiento/getCoordenadaByEstablecimiento/',
      filter,
    )
  }

  static getEstablecimientoPuerto = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getEstablecimientosPuerto/', filter)
  }

  static getEstablecimientos2 = filter => {
    return RestClientObj.instanceAxios.post('/establecimiento/getEstablecimientos/', filter)
  }

  static getEstablecimientoPorCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientosPorCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEstablecimientoByRucRazonSocial = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientoByRucRazonSocial/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getEstablecimientoByEmpresa = filter => {
    return RestClientObj.instanceAxios
      .post('/establecimiento/getEstablecimientoByEmpresa/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listaOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/agregar_orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/eliminar_orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarOrdenPagoDetalleTipo = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_orden_pago_detalle_tipo/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listaOrdenesPago = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarOrdenCancelado = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/actualizarOrdenCancelado/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getDetalleOrden = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/getDetalleOrden', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static registrarOrdenFactura = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/registrarOrdenFactura', filter)
      .then(response => response.data)
  }

  static guardarRuta = filter => {
    return RestClientObj.instanceAxios.post('/admin/ruta_waypoint/addRutaWaypoint/', filter)
  }

  static getWaypointSugeridos = filter => {
    return RestClientObj.instanceAxios.post('/admin/ruta_waypoint/getWaypoint/', filter)
  }

  static getRutaByCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/ruta_waypoint/getRutaByCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static deleteRutaWaypoint = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/ruta_waypoint/deleteRuta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static updateRutaWaypoint = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/ruta_waypoint/updateRuta/', filter)
      .then(response => response.data)
  }

  static getListaCamiones = filter => {
    return RestClientObj.instanceAxios.post('/camion/', filter)
  }

  static getListaEjes = filter => {
    return RestClientObj.instanceAxios
      .get('/camion/getListaEjes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarInfoCamion = filter => {
    return RestClientObj.instanceAxios
      .post('/camion/actualizarInfoCamion', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getListaChoferes = filter => {
    return RestClientObj.instanceAxios.post('/chofer/', filter)
  }

  static getChofer = filter => {
    return RestClientObj.instanceAxios.post('/chofer/getChofer', filter)
  }

  static getEstadoRutasChofer = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/estadoRutasChofer', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static misChoferes = filter => {
    return RestClientObj.instanceAxios
      .post('/chofer/misChoferes', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static deleteChofer = filter => {
    return RestClientObj.instanceAxios.post('/chofer/deleteChofer', filter)
  }

  static getCamion = filter => {
    return RestClientObj.instanceAxios.post('/camion/getCamion', filter)
  }

  static deleteCamion = filter => {
    return RestClientObj.instanceAxios.post('/camion/deleteCamion', filter)
  }

  static eliminarTransportista = filter => {
    return RestClientObj.instanceAxios.post('/admin/transportista/deleteTransportista/', filter)
  }

  static getListaTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getListaTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getTarifasTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/tarifasTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static calcularCostoEstimadoTransportista = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/costoEstimadoTransportista/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static verDetalleCosto = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/getDetalleCosto/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static costoEstimadoListaTransportistaFavorito = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/transportista/costoEstimadoListaTransportistaFavorito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static liberarTransportistaReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/liberarReserva/', filter)
      .then(response => response.data)
  }

  // TarifaCiudad
  static agregarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/addTarifaciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarTarifaCiudadExcel = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/addTarifaciudadExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/deleteTarifaciudad', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTarifaCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaCiudad/updateTarifaciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // TarifaClienteCiudad
  static agregarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/addTarifaClienteciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarTarifaClienteCiudadExcel = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/addTarifaClienteciudadExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/deleteTarifaClienteciudad', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTarifaClienteCiudad = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaClienteCiudad/updateTarifaClienteciudad/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // TarifaPlanta
  static agregarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/addTarifaPlanta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static consultarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/deleteTarifaPlanta', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTarifaPlanta = filter => {
    return RestClientObj.instanceAxios
      .post('/tarifa/tarifaPlanta/updateTarifaPlanta/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // servicioTransporte
  static consultarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/addServicioTransporte/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/updateServicioTransporte/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/deleteServicioTransporte', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getServicioTransporte = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporte/getServicioTransporte', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  // servicioTransporteCliente
  static consultarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/addServicioTransporteCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/deleteServicioTransporteCliente', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarServicioTransporteCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/servicio/servicioTransporteCliente/updateServicioTransporteCliente/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }
  // credito
  static listadoCreditoUsuario = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/credito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarCreditoUsuario = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/credito/addCredito', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarCreditoUsuario = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/credito/updateCredito', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarCreditoExcel = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/admin/credito/addCreditoExcel/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarCredito = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/admin/credito/deleteCredito/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }
  // rubros
  static listadoRubros = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/rubro/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static listadoTipoRubros = () => {
    return RestClientObj.instanceAxios
      .get('/admin/rubro/lista_tipo_rubro')
      .then(response => response.data)
      .catch(error => error.response)
  }

  static agregarRubro = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/rubro/addRubro', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarRubro = filter => {
    return RestClientObj.instanceAxios
      .post('/admin/rubro/updateRubro', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static eliminarRubro = filter => {
    this.setAuthorization()
    return RestClientObj.instanceAxios
      .post('/admin/rubro/deleteRubro/', filter)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static getSolicitudReservas = () => {
    return RestClientObj.instanceAxios
      .get('/reservas/pendientes_confirmar')
      .then(response => response.data)
  }

  static aceptarSolicitudReserva = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/evaluar_solicitud', reserva)
      .then(response => response.data)
  }

  static actualizarChoferReserva = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizarChoferReserva', form)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTransportistaReserva = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizarTransportistaReserva', form)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static actualizarTransportistaSeleccionados = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/notificarTransportistasSeleccionados', form)
      .then(response => response.data)
      .catch(error => error.response)
  }

  static notificarTransportistasFavoritos = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/notificarTransportistasFavoritos', form)
      .then(response => response.data)
  }

  static listaContenedoresExportacion = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/getContenedoresExportacion', form)
      .then(response => response.data)
  }

  static eliminarContenedorExportacion = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/deleteContenedoresExportacion', form)
      .then(response => response.data)
  }

  static agregarContenedorExportacion = form => {
    return RestClientObj.instanceAxios
      .post('/reservas/addContenedoresExportacion', form)
      .then(response => response.data)
  }

  static consultarDocumentosImport = form => {
    return RestClientObj.instanceAxios
      .post('/dpw/cliente_contenedor', form)
      .then(response => response.data)
  }

  static consultarDocumentosExport = form => {
    return RestClientObj.instanceAxios
      .post('/dpw/cliente_booking', form)
      .then(response => response.data)
  }

  static getMisReservas = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/misReservas', filter)
      .then(response => response.data)
  }

  static getMisReservasAdmin = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/misReservasAdmin', filter)
      .then(response => response.data)
  }

  static getMisReservasCliente = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/misReservasCliente', filter)
      .then(response => response.data)
  }

  static generarFacturaFOCAL = filter => {
    return RestClientObj.instanceAxios
      .post('/ordenes/facturar_orden', filter)
      .then(response => response.data)
  }

  static getHitosReserva = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/get_hitos', filter)
      .then(response => response.data)
  }

  static getUbicacionesHitos = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/ubicacionesHitos', filter)
      .then(response => response.data)
  }

  static getUltimaUbicacionHito = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/getUltimaUbicacion', filter)
      .then(response => response.data)
  }

  static getUltimaUbicacionReservasEnCurso = filter => {
    return RestClientObj.instanceAxios
      .post('/reservas/ultimaUbicacionEnCurso', filter)
      .then(response => response.data)
  }

  static getTipo = () => {
    return RestClientObj.instanceAxios
      .get('/establecimiento/getTipo')
      .then(response => response.data)
  }

  static updateFirebaseToken = token => {
    return RestClientObj.instanceAxios
      .post('/users/update_firebase_token', token)
      .then(response => response.data)
  }
  /**
   * Consulta al servicio de DP las disponibilidades del día seleccionado
   * params:
   *    {
   *        desde: string - "yyyy-mm-dd HH:mm:ss"
   *        hasta: string - "yyyy-mm-dd HH:mm:ss"
   *    }
   */
  static consultarDisponibilidad = dateRange => {
    return RestClientObj.instanceAxios
      .post('/dpw/disponibilidad', dateRange)
      .then(response => response.data)
  }

  static consultarDashboardGantts = postData => {
    return RestClientObj.instanceAxios
      .post('/reservas/dashboard_gantts', postData)
      .then(response => response.data)
  }

  static misCamiones = postData => {
    return RestClientObj.instanceAxios
      .post('/camion/misCamiones', postData)
      .then(response => response.data)
  }
  static actualizarCamionChofer = postData => {
    return RestClientObj.instanceAxios
      .post('/reservas/actualizar_camion_chofer', postData)
      .then(response => response.data)
  }

  // dashboard
  static estadoReservaCliente = postData => {
    return RestClientObj.instanceAxios
      .post('/dashboard/estadoReservasCliente', postData)
      .then(response => response.data)
  }

  static cantidadReservaCliente = postData => {
    return RestClientObj.instanceAxios
      .post('/dashboard/cantidadReservasCliente', postData)
      .then(response => response.data)
  }

  static estadoReservaFacturadasCliente = postData => {
    return RestClientObj.instanceAxios
      .post('/dashboard/estadoReservasFacturadasCliente', postData)
      .then(response => response.data)
  }

  static getDocumentosVacios = reserva => {
    return RestClientObj.instanceAxios
      .post('/dpw/get_documentos_vacios', reserva)
      .then(response => response.data)
  }
  static reservarVacioPorReserva = reserva => {
    return RestClientObj.instanceAxios
      .post('/reservas/reservar_vacio', reserva)
      .then(response => response.data)
  }
}
