import React from 'react'
import firebase from 'firebase/app'
import RestClient from './services/restClient'

require('firebase/messaging')

export default class FirebaseStartComponent extends React.PureComponent {
  static config = {
    apiKey: 'AIzaSyBsMitBe5gwdOHKzS2OFfr8FMTjR50ZZBk',
    authDomain: 'fmsapp-ed4d8.firebaseapp.com',
    databaseURL: 'https://fmsapp-ed4d8.firebaseio.com',
    projectId: 'fmsapp-ed4d8',
    storageBucket: 'fmsapp-ed4d8.appspot.com',
    messagingSenderId: '350463653199',
    appId: '1:350463653199:web:deacecdbd972bfa0',
  }
  constructor(props) {
    super(props)
    this.state = {
      messaging: undefined,
    }
  }

  componentDidMount() {
    try {
      firebase.initializeApp(FirebaseStartComponent.config)
      this.setState({ messaging: firebase.messaging() }, () => {
        this.askPermission()
      })
    } catch (error) {
      console.log(error)
    }
  }

  askPermission = async () => {
    try {
      await this.state.messaging.requestPermission()
      const token = await this.state.messaging.getToken()
      //  guardar token
      console.log('token ask', token)
      await RestClient.updateFirebaseToken({ firebaseToken: token })

      return token
    } catch (error) {
      console.error(error)
    }
  }

  render() {
    return null
  }
}
