import React from 'react'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  state = {
    // count: 7,
  }

  componentDidMount() {
    console.log(this.props.profile)
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'LOGOUT',
    })
  }

  // addCount = () => {
  //   let { count } = this.state
  //   count += 1
  //   // this.setState({
  //   //   count,
  //   // })
  // }

  render() {
    const { user } = this.props
    // const { count } = this.state
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>{this.props.profile.razon_social || 'Anonymous'}</strong>
          <div>
            <strong className="mr-1">Ruc:</strong>
            {this.props.profile.ruc_empresa}
          </div>
          <div>
            <strong>Perfil:</strong>
            {this.props.profile.perfil}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="javascript: void(0);" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <span>
        <span className={styles.usernameStyle}>{this.props.profile.username}</span>
        <Dropdown overlay={menu} trigger={['click']}>
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
        </Dropdown>
      </span>
    )
  }
}

export default connect(state => {
  return {
    profile: state.user.profile,
  }
})(ProfileMenu)
